import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  { path: "", redirectTo: "es", pathMatch: "full" },
  {
    path: ":language",
    loadChildren: () => import("./pages/home/home.module").then(m => m.HomePageModule),
    data: { footerClassName: "gray" },
  },
  {
    path: ":language/privacy",
    loadChildren: () => import("./pages/privacy/privacy.module").then(m => m.PrivacyPageModule),
    data: { footerClassName: "gray" },
  },
  {
    path: ":language/terms",
    loadChildren: () => import("./pages/terms/terms.module").then(m => m.TermsPageModule),
    data: { footerClassName: "gray" },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: "enabledNonBlocking",
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
