import { DOCUMENT, isPlatformServer } from "@angular/common";
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class SeoService {

  private langToHref: { [key: string]: string } = {
    es: "es-es",
    en: "en-gb",
    ca: "es-ca",
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    private title: Title,
    private meta: Meta,
    private i18n: TranslateService,
  ) {}

  public async setMetadata(page?: "home" | "privacy" | "terms"): Promise<void> {
    while ((this.i18n as any).pending) {
      console.warn(`${Date.now()} Waiting for translations`);
      await new Promise(resolve => setTimeout(() => resolve(null), 100));
    }

    if (!environment.production) {
      this.meta.updateTag({ name: "robots", content: "noindex,nofollow" });
    }

    this.title.setTitle(this.i18n.instant("Seo.Title"));
    this.meta.updateTag({ name: "description", content: this.i18n.instant("Seo.Description") });

    this.meta.addTag({ property: "og:type", content: "webpage" });
    this.meta.addTag({ property: "og:site_name", content: "Oh My Menu" });
    this.meta.addTag({ property: "og:title", content: this.i18n.instant("Seo.Title") });
    this.meta.addTag({ property: "og:description", content: this.i18n.instant("Seo.Description") });
    this.meta.addTag({ property: "og:image", content: "/assets/icon/logox2.png" });

    this.meta.addTag({ property: "twitter:title", content: this.i18n.instant("Seo.Title") });
    this.meta.addTag({ property: "twitter:description", content: this.i18n.instant("Seo.Description") });

    if (page && isPlatformServer(this.platformId)) {
      this.setCanonical(`https://ohmymenu.com/${this.i18n.currentLang}${page === "home" ? "" : `/${page}`}`);
      Object.keys(this.langToHref)
        .filter(language => language !== this.i18n.currentLang)
        .forEach(language => this.setHreflang(`https://ohmymenu.com/${language}${page === "home" ? "" : `/${page}`}`, language));
    }
  }

  private setCanonical(url: string): void {
    const link = this.document.createElement("link");
    link.setAttribute("rel", "canonical");
    link.setAttribute("href", url);
    this.document.head.appendChild(link);
  }

  private setHreflang(url: string, language: string): void {
    const link = this.document.createElement("link");
    link.setAttribute("rel", "alternate");
    link.setAttribute("hreflang", this.langToHref[language]);
    link.setAttribute("href", url);
    this.document.head.appendChild(link);
  }
}
