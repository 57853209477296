import { isPlatformBrowser, Location } from "@angular/common";
import { Component, HostListener, Inject, InjectionToken, OnInit, PLATFORM_ID } from "@angular/core";
import { ActivationEnd, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SeoService } from "./services/seo/seo.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public isPagesSectionOpened?: boolean;
  public isContactSectionOpened?: boolean;
  public isFollowUsSectionOpened?: boolean;
  public footerRouterClass: string;
  public isHeaderSticky?: boolean;
  public showScrollTop?: boolean;
  public language?: string;

  constructor(
    private location: Location,
    private router: Router,
    private i18n: TranslateService,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
  ) {
    this.footerRouterClass = "";
    this.language = this.location.path().slice(1, 3) || "es";
    this.i18n.use(this.language);

    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.footerRouterClass = event.snapshot.data.footerClassName;
      } else if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    });
  }

  @HostListener("window:scroll", ["$event"])
  protected onWindowScroll(): void {
    this.isHeaderSticky = !!window.scrollY;
    this.showScrollTop = window.scrollY > 800;
  }

  public async ngOnInit(): Promise<void> {
    await this.seoService.setMetadata();
  }

  public togglePagesSection(): void {
    this.isPagesSectionOpened = !this.isPagesSectionOpened;
  }

  public toggleContactSection(): void {
    this.isContactSectionOpened = !this.isContactSectionOpened;
  }

  public toggleFollowUsSection(): void {
    this.isFollowUsSectionOpened = !this.isFollowUsSectionOpened;
  }

  public scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  public changeLanguage(language: string): void {
    window.open(language, "_self");
  }
}
