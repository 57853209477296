import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class LeadService {
  constructor(private http: HttpClient) { }

  public storeLead(email: string): Promise<any> {
    return this.http
      .post(
        "https://hook.integromat.com/so1zrxliyzwncqb3klj7k9ixusys7mey",
        { email, date: Date.now(), production: environment.production },
        { responseType: "text" },
      ).toPromise();
  }
}
