<header class="header clearfix element_to_stick" [class.sticky]="sticky">
  <div class="container">

    <div id="logo">
      <a [routerLink]="[language]">
        <img src="assets/icon/logo.svg" height="50" alt="" class="logo_normal">
        <img src="assets/icon/logo.svg" height="50" alt="" class="logo_sticky">
      </a>
    </div>

    <ul id="top_menu">
      <li><a href="https://admin.ohmymenu.com?overwrite_language={{language}}" id="sign-in" class="login"></a></li>
    </ul>

    <nav class="main-menu">
      <div id="header_menu">
        <a [routerLink]="[language]"><img src="assets/icon/logo.svg" height="50" alt=""></a>
      </div>
      <ul>
        <li><a href="https://admin.ohmymenu.com?overwrite_language={{language}}">
          {{ "Header.Login" | translate }}
        </a></li>
      </ul>
    </nav>
  </div>
</header>
