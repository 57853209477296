import { Component, Input } from "@angular/core";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  @Input() public sticky?: boolean;
  @Input() public customClass?: string;
  @Input() public language?: string;

  constructor() {}
}
