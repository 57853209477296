export const environment = {
  production: true,
  name: "production",
  release: "0.0.91",
  firebase: {
    apiKey: "AIzaSyBZM3lyTqD7CJnFeXaGe2E-zaXK3Ahtq0M",
    authDomain: "com-ohmymenu.firebaseapp.com",
    databaseURL: "https://com-ohmymenu.firebaseio.com",
    projectId: "com-ohmymenu",
    storageBucket: "com-ohmymenu.appspot.com",
    messagingSenderId: "229737233171",
    appId: "1:229737233171:web:8c5f1e7c547d26e03838e6",
    measurementId: "G-NYFKVEJQBP"
  },
};
