import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule, TransferState } from "@angular/platform-browser";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterModule } from "./components/footer/footer.module";
import { HeaderModule } from "./components/header/header.module";
import { SentryService } from "./services/sentry/sentry.service";
import { AngularFireAnalyticsModule, APP_NAME, APP_VERSION, DEBUG_MODE, ScreenTrackingService } from "@angular/fire/compat/analytics";
import { AngularFireModule } from "@angular/fire/compat";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { createBrowserTranslateLoader } from "./services/i18n/translate-loader";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { CookieBarModule } from "./components/cookie-bar/cookie-bar.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    TransferHttpCacheModule,
    TranslateModule.forRoot({
      defaultLanguage: "es",
      loader: {
        provide: TranslateLoader,
        useFactory: createBrowserTranslateLoader,
        deps: [HttpClient, TransferState],
      },
    }),
    AppRoutingModule,
    HttpClientModule,
    FooterModule,
    HeaderModule,
    CookieBarModule,
    AngularFireModule.initializeApp(environment.firebase, "com-ohmymenu"),
    AngularFireAnalyticsModule,
  ],
  providers: [
    ScreenTrackingService,
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: ErrorHandler, useClass: SentryService },
    { provide: APP_NAME, useValue: "com-ohmymenu" },
    { provide: APP_VERSION, useValue: environment.release },
    { provide: DEBUG_MODE, useValue: !environment.production },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
