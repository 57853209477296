import { isPlatformBrowser } from "@angular/common";
import { ErrorHandler, Inject, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class SentryService extends ErrorHandler {

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
  ) {
    super();
    this.init();
  }

  public handleError(error: any): void {
    super.handleError(error);
    try {
      if (/Loading chunk [\d]+ failed/.test(error.message)) {
        window.location.reload();
      } else {
        Sentry.captureException(error.originalError || error);
      }
    } catch (e) {
      console.error(e);
    }
  }

  private init(): void {
    if (environment.production && isPlatformBrowser(this.platformId)) {
      Sentry.init({
        release: `ohmymenu@${environment.release}`,
        dsn: "https://4ed2568cbc134404b0149f1218c915ef@o543353.ingest.sentry.io/5724870",
        environment: environment.name,
        attachStacktrace: true,
        autoSessionTracking: true,
        integrations: [
          new Integrations.BrowserTracing({
            tracingOrigins: ["localhost"],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
        tracesSampleRate: 1.0,
      });
    }
  }
}
