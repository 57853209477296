import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, InjectionToken, Input, OnInit, PLATFORM_ID } from "@angular/core";

@Component({
  selector: "app-cookie-bar",
  templateUrl: "./cookie-bar.component.html",
  styleUrls: ["./cookie-bar.component.scss"],
})
export class CookieBarComponent implements OnInit {
  @Input() public language?: string;

  public shouldShow?: boolean;
  public shouldHide?: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
  ) {}

  public ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.shouldShow = !localStorage.getItem("cookies");
    }
  }

  public acceptCookies(): void {
    localStorage.setItem("cookies", "1");
    this.shouldHide = true;
    setTimeout(() => this.shouldShow = false, 900);
  }
}
