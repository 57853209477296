<app-header [language]="language" [sticky]="isHeaderSticky"></app-header>

<router-outlet></router-outlet>

<app-footer
    [language]="language"
    [customClass]="footerRouterClass"
    [isPagesSectionOpened]="isPagesSectionOpened"
    [isContactSectionOpened]="isContactSectionOpened"
    [isFollowUsSectionOpened]="isFollowUsSectionOpened"
    (togglePagesSection)="togglePagesSection()"
    (toggleContactSection)="toggleContactSection()"
    (toggleFollowUsSection)="toggleFollowUsSection()"
    (changeLanguage)="changeLanguage($event)"
></app-footer>

<div id="toTop" [class.visible]="showScrollTop" (click)="scrollToTop()"></div>

<app-cookie-bar [language]="language"></app-cookie-bar>
