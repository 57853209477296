import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LeadService } from "src/app/services/lead/lead.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  @Input() public isPagesSectionOpened?: boolean;
  @Input() public isContactSectionOpened?: boolean;
  @Input() public isFollowUsSectionOpened?: boolean;
  @Input() public customClass?: string;
  @Input() public language?: string;

  @Output() public togglePagesSection: EventEmitter<void> = new EventEmitter();
  @Output() public toggleContactSection: EventEmitter<void> = new EventEmitter();
  @Output() public toggleFollowUsSection: EventEmitter<void> = new EventEmitter();
  @Output() public changeLanguage: EventEmitter<string> = new EventEmitter();

  public form?: FormGroup;
  public showSuccessMessage?: boolean;
  public loading?: boolean;
  public email?: string;

  constructor(private leadService: LeadService) {}

  public ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  public async storeLead(): Promise<void> {
    this.email = this.form?.value.email;
    if (!this.email) {
      return;
    }

    await this.leadService.storeLead(this.email);
    this.showSuccessMessage = true;
  }

  public _changeLanguage(event: any): void {
    this.changeLanguage.emit(event.target.value);
  }
}
