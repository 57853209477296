<footer>
  <div class="wave footer {{customClass}}"></div>
  <div class="container margin_60_40 fix_mobile">

    <div class="row">
      <div class="col-lg-4 col-md-6">
        <h3 [class.opened]="isPagesSectionOpened" (click)="togglePagesSection.emit()">
          {{ "Footer.Pages" | translate }}
        </h3>
        <div class="collapse dont-collapse-sm links" [class.show]="isPagesSectionOpened">
          <ul>
            <li><a href="https://admin.ohmymenu.com?overwrite_language={{language}}">
              {{ "Footer.Login" | translate }}
            </a></li>
            <li><a [routerLink]="[language, 'terms']">
              {{ "Footer.TermsAndConditions" | translate }}
            </a></li>
            <li><a [routerLink]="[language, 'privacy']">
              {{ "Footer.PrivacyPolicy" | translate }}
            </a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <h3 [class.opened]="isContactSectionOpened" (click)="toggleContactSection.emit()">
          {{ "Footer.Contact" | translate }}
        </h3>
        <div class="collapse dont-collapse-sm contacts" [class.show]="isContactSectionOpened">
          <ul>
            <li><i class="icon_house_alt"></i>Miquel Verdera, 19 07420<br>Sa Pobla - ES</li>
            <li><i class="icon_mobile"></i>+34 671 641 565</li>
            <li><i class="icon_mail_alt"></i><a>hello@ohmymenu.com</a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <h3 [class.opened]="isFollowUsSectionOpened" (click)="toggleFollowUsSection.emit()">
          {{ "Footer.KeepInTouch" | translate }}
        </h3>
        <div class="collapse dont-collapse-sm" [class.show]="isFollowUsSectionOpened">
          <div id="newsletter">
            <div *ngIf="showSuccessMessage" [innerHTML]="'Footer.SuccessMessage' | translate:{ email: email }"></div>
            <form [formGroup]="form!" *ngIf="!showSuccessMessage">
              <div class="form-group">
                <input type="email" class="form-control" formControlName="email" placeholder="{{ 'Footer.EmailPlaceholder' | translate}}">
                <button [disabled]="form?.invalid || loading" type="submit" (click)="storeLead()">
                  <i class="arrow_carrot-right"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="col-lg-4 col-md-6">
      <h3 data-target="#collapse_4">Síguenos</h3>
      <div class="collapse dont-collapse-sm" id="collapse_4">
        <div id="newsletter">
          <div class="follow_us">
            <ul>
              <li><a><img src="assets/img/twitter_icon.svg" alt="Twitter icon" class="lazy"></a></li>
              <li><a><img src="assets/img/facebook_icon.svg" alt="Facebook icon" class="lazy"></a></li>
              <li><a><img src="assets/img/instagram_icon.svg" alt="Instagram icon" class="lazy"></a></li>
              <li><a><img src="assets/img/youtube_icon.svg" alt="Youtube icon" class="lazy"></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->

    <hr>
    <div class="row add_bottom_25">
      <div class="col-lg-6">
        <ul class="footer-selector clearfix">
          <li>
            <div class="styled-select lang-selector">
              <select (change)="_changeLanguage($event)">
                <option value="es" [attr.selected]="language === 'es' || undefined">{{ "Language.SPANISH" | translate }}</option>
                <option value="en" [attr.selected]="language === 'en' || undefined">{{ "Language.ENGLISH" | translate }}</option>
                <option value="ca" [attr.selected]="language === 'ca' || undefined">{{ "Language.CATALAN" | translate }}</option>
                <option disabled value="de" [attr.selected]="language === 'de' || undefined">{{ "Language.GERMAN" | translate }}</option>
                <option disabled value="it" [attr.selected]="language === 'it' || undefined">{{ "Language.ITALIAN" | translate }}</option>
              </select>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-lg-6">
        <ul class="additional_links">
          <li><a [routerLink]="[language, 'terms']">
            {{ "Footer.TermsAndConditions" | translate }}
          </a></li>
          <li><a [routerLink]="[language, 'privacy']">
            {{ "Footer.PrivacyPolicy" | translate}}
          </a></li>
          <li><span>Oh My Menu®</span></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
